// react
import React, { lazy, Suspense, useContext, useReducer } from 'react';
import { ReactQueryConfigProvider } from 'react-query';

// antd
import { ConfigProvider, theme } from 'antd';

// bb
import isPublicPortal from 'constants/IS_PUBLIC_PORTAL';
import isAdsPortal from 'constants/IS_ADS_PORTAL';
import isDevPortal from 'constants/IS_DEV_PORTAL';

import ThemeContext from 'pages/ThemeContext';
import { NotificationProvider } from 'components/notification/Notification';
import ErrorBoundary from 'components/layout/ErrorBoundary';

// side effects
import 'antd/dist/reset.css';

// lazy
const PublicApp = lazy(() => import('pages/_public-app'));
const AuthedApp = lazy(() => import('pages/_authed-app'));

const reactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

export function App() {
  const themeContext = useContext(ThemeContext);

  const antdConfig = {
    theme: {
      algorithm: themeContext.theme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm,
      token: {
        colorBgContainer2: themeContext.theme === 'light' ? '#FAFAFA' : '#191919',
      },
    },
  };

  return (
    <ConfigProvider {...antdConfig}>
      <NotificationProvider>
        <ErrorBoundary>
          <ReactQueryConfigProvider config={reactQueryConfig}>
            <Suspense fallback={null}>
              {isPublicPortal && <PublicApp />}
              {(isAdsPortal || isDevPortal) && <AuthedApp />}
            </Suspense>
          </ReactQueryConfigProvider>
        </ErrorBoundary>
      </NotificationProvider>
    </ConfigProvider>
  );
}

export default function ThemedApp() {
  let defaultTheme = localStorage.getItem('theme');

  if (!defaultTheme) {
    const userPrefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (isPublicPortal && userPrefersDarkMode) {
      defaultTheme = 'dark';
    } else {
      defaultTheme = 'light';
    }
  }

  const [theme, toggleTheme] = useReducer((currentTheme) => {
    let newTheme;

    switch (currentTheme) {
      case 'light':
        newTheme = 'dark';
        break;

      case 'dark':
        newTheme = 'light';
        break;

      default:
        newTheme = 'light';
    }

    localStorage.setItem('theme', newTheme);

    return newTheme;
  }, defaultTheme);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {theme === 'dark' && <style>{`:root { color-scheme: dark }`}</style>}

      <App />
    </ThemeContext.Provider>
  );
}
